import React, { useEffect, useState, useRef } from 'react';
import { JsonRpcProvider } from 'ethers';
import './BlockInfoBar.css';
import BlockInfo from './BlockInfo';

const BlockInfoBar = () => {
	const [blocks, setBlocks] = useState([]);
	const blocksRef = useRef([]);
	const animationDuration = blocks.length * 30;
	
	useEffect(() => {
		const provider = new JsonRpcProvider('https://api.zeniq.network/');
		
		const fetchInitialBlocks = async () => {
			try {
				const latestBlockNumber = await provider.getBlockNumber();
				const N = 20;
				const blockNumbers = Array.from({ length: N }, (_, i) => latestBlockNumber - i);
				const blockPromises = blockNumbers.map((number) => provider.getBlock(number, true));
				const initialBlocks = await Promise.all(blockPromises);
				
				blocksRef.current = initialBlocks.reverse();
				setBlocks([...blocksRef.current]);
			} catch (error) {
				console.error('Error fetching initial blocks:', error);
			}
		};
		
		fetchInitialBlocks();
		
		const handleNewBlock = async (blockNumber) => {
			try {
				const block = await provider.getBlock(blockNumber, true);
				blocksRef.current.push(block);
				if (blocksRef.current.length > 40) {
					blocksRef.current.shift();
				}
				setBlocks([...blocksRef.current]);
			} catch (error) {
				console.error('Error fetching new block:', error);
			}
		};
		
		provider.on('block', handleNewBlock);
		
		return () => {
			provider.off('block', handleNewBlock);
			provider.destroy();
		};
	}, []);
	
	return (
		<a href="https://zeniqscan.com/" className="block-info-bar">
			<div
				className="block-info-track"
				style={{ animationDuration: `${animationDuration}s` }}
			>
				{[...blocks, ...blocks].map((block, index) => (
					<BlockInfo key={`block-${index}-${block.number}`} block={block} />
				))}
			</div>
		</a>
	);
};

export default BlockInfoBar;
