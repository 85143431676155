import React from 'react';
import './ProjectCard.css';

const ProjectCard = ({ title, description, imageUrl, link }) => {
	return (
		<div className="project-card">
			{link ? (
				<a href={link} className="card-link" target="_blank" rel="noopener noreferrer">
					<div className="project-content">
						<div className="title">
							<img src={imageUrl} width={200} height={200} alt={title} className="project-image" />
							<h2>{title}</h2>
						</div>
						<p>{description}</p>
					</div>
				</a>
			) : (
				<div className="project-content">
					<div className="title">
						<img src={imageUrl} width={200} height={200} alt={title} className="project-image" />
						<h2>{title}</h2>
					</div>
					<p>{description}</p>
				</div>
			)}
		</div>
	);
};

export default ProjectCard;
