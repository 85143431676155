import React from 'react';
import './MainCard.css';
import eco360Image from './eco360.png';

const MainCard = ({ title, description }) => {
	return (
		<div className="main-card">
			<img src={eco360Image} alt="360 Ecosystem" className="card-image" />
			<div className="overlay-content">
				<h2>360° ECOSYSTEM</h2>
				<p>Self sustaining blockchain <br/> infrastructure</p>
			</div>
		</div>
	);
};

export default MainCard;
