import React from 'react';

const BlockInfo = React.memo(({ block }) => {
	const shortenAddress = (address) => {
		if (!address) return '';
		return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
	};
	
	return (
		<div className="block-info">
			<span><strong>Block:</strong> {block.number}</span>
			<span><strong>Transactions:</strong> {block.transactions.length}</span>
			<span><strong>Miner:</strong> {shortenAddress(block.miner)}</span>
		</div>
	);
});

export default BlockInfo;
