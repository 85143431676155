import React from 'react';
import './Footer.css';

export const Footer = () => {
	return (
		<>
			<div className="flex-row">
				<div className="yellow-bar"/>
				<div className="gray-bar"/>
			</div>
			<div className="footer">
				<div className="logo-black">ZENIQ Ecosystem</div>
			{/*	<div className="live">*/}
			{/*		<div className="blinking"></div>*/}
			{/*		<div className="live-text">LIVE</div>*/}
			{/*	</div>*/}
			</div>
		
		</>
	)
}

export const FooterBar = () => {
	return (
		<>
			<div className="flex-row">
				<div className="yellow-bar"/>
				{/*<div className="gray-bar"/>*/}
			</div>
		</>
	)
}