import React from 'react';
import './CompanyCard.css';

const CompanyCard = ({ description, imageUrl, link }) => {
	return (
		<div className="company-card">
			{link ? (
				<a href={link} className="card-link" target="_blank" rel="noopener noreferrer">
					<div className="company-content">
						<img src={imageUrl} alt="Company Logo" className="company-image" />
						<p>{description}</p>
					</div>
				</a>
			) : (
				<div className="company-content">
					<img src={imageUrl} alt="Company Logo" className="company-image" />
					<p>{description}</p>
				</div>
			)}
		</div>
	);
};

export default CompanyCard;
