import React from 'react';
import './Navbar.css';

export const Navbar = () => {
	return (
		<>
			{/*<div className="flex-row">*/}
			{/*	<div className="yellow-bar"/>*/}
			{/*	<div className="gray-bar"/>*/}
			{/*</div>*/}
			<div className="navbar">
				<div className="logo">ZENIQ Ecosystem</div>
				<div className="live">
					<div className="blinking"></div>
					<div className="live-text">LIVE</div>
				</div>
			</div>
		
		</>
	)
}