import React from 'react';
import { FaTelegramPlane } from 'react-icons/fa';
import './NomoNewsCTA.css';

const NomoNewsCTA = () => {
	return (
		<div className="nomo-news-cta">
			<h1 className="cta-title">Stay Updated with Nomo News</h1>
			<div className="cta-content">
				<img
					src="/images/nomonews.png"
					alt="Nomo News Telegram"
					className="cta-image"
				/>
				<div className="cta-text-block">
					<p className="cta-text">
						Join the Nomo News Telegram Group to get the latest updates on the ZENIQ Ecosystem. Stay informed about new features, upcoming releases, and all the exciting developments in aerospace, real estate, DeFi, and more.
					</p>
					
					{/* Container for the buttons */}
					<div className="cta-buttons">
						{/* Button for English */}
						<a
							href="https://t.me/nomo_news_en"
							className="cta-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegramPlane className="cta-icon" /> EN <img src="/images/flags/en.png" alt="EN Flag" className="flag-icon"/>
						</a>
						
						{/* Button for German */}
						<a
							href="https://t.me/nomo_news_de"
							className="cta-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegramPlane className="cta-icon" /> DE <img src="/images/flags/de.png" alt="DE Flag" className="flag-icon"/>
						</a>
						
						{/* Button for Spanish */}
						<a
							href="https://t.me/nomo_news_es"
							className="cta-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegramPlane className="cta-icon" /> ES <img src="/images/flags/es.png" alt="ES Flag" className="flag-icon"/>
						</a>
						
						{/* Button for French */}
						<a
							href="https://t.me/nomo_news_fr"
							className="cta-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegramPlane className="cta-icon" /> FR <img src="/images/flags/fr.png" alt="FR Flag" className="flag-icon"/>
						</a>
						
						{/* Button for Russian */}
						<a
							href="https://t.me/nomo_news_ru"
							className="cta-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegramPlane className="cta-icon" /> RU <img src="/images/flags/ru.png" alt="RU Flag" className="flag-icon"/>
						</a>
						
						{/* Button for Brazilian Portuguese */}
						<a
							href="https://t.me/nomo_news_pt"
							className="cta-button"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FaTelegramPlane className="cta-icon" /> PT <img src="/images/flags/pt-br.png" alt="PT Flag" className="flag-icon"/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NomoNewsCTA;
